import React from "react";

export const Home = () => {
  return (
    <>
      <div className="homepage">
        <div id="home">
          <p className="home-text">
            Make a real impact on the elections that determine the balance of
            power in our country.
          </p>
        </div>
      </div>
    </>
  );
};
