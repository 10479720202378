import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Calendar, dateFnsLocalizer, DateLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import useFetchEvents from "../../hooks/useFetchEvents";

const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

export default function NewCalendar() {
  const defaultDate = useMemo(() => new Date(), []);
  const { events, isError } = useFetchEvents("10011", 20, 1000);

  return (
    <div className="height600">
      {isError && <div>There was a problem loading the event calendar</div>}
      <Calendar
        defaultDate={defaultDate}
        defaultView="agenda"
        events={events}
        localizer={localizer}
        popup
        length={7}
        views={["agenda"]}
        components={{
          event: ({ event }) => (
            <span>
              <strong>{event.title}</strong>
              <div>
                <a href={event.link} target="_blank" rel="noopener noreferrer">
                  Sign up here!
                </a>
                <div>Location: {event.location}</div>
              </div>
            </span>
          ),
        }}
      />
    </div>
  );
}
NewCalendar.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
};
