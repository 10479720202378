import React from "react";
import { Button } from "../Button.jsx";

export const Modal = ({ open, onClose }) => {
  if (!open) return null;
  return (
    <>
      <div className="overlay" />
      <div className="modal">
        <div className="row">
          <h1 className="modal-title">Take Action With SwingLeft NYC</h1>
          <Button
            onClick={onClose}
            buttonSize="btn--small"
            buttonStyle="btn--navy"
            id="xButton"
          >
            X
          </Button>
        </div>
        <h2 className="modal-h2">Here are some ways you can get involved:</h2>
        <a
          className="modal-item"
          href="https://swingleft.org/take-action?s=u"
          target="_blank"
          rel="noopener noreferrer"
        >
          Find volunteer opportunities!
        </a>
        <a
          className="modal-item"
          href="https://www.nycvotes.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Vote!
        </a>
        <a
          className="modal-item"
          href="https://swingleft.org/group/swing-left-nyc"
          target="_blank"
          rel="noopener noreferrer"
        >
          Join Our Group!
        </a>
      </div>
    </>
  );
};
