import "react-toastify/dist/ReactToastify.min.css";

import { Button, TextField } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";

import React from "react";
import emailjs from "emailjs-com";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";

const useStyles = makeStyles(() => ({
  button: {
    size: 40,
    fontWeight: "bold",
    margin: 3,
    backgroundColor: "#00c4ff",
    fontFamily: "open_sansbold",
  },

  nameInput: {
    width: "40%",
    "@media screen and (max-width: 780px)": {
      width: "80%",
      margin: 10,
    },
  },

  emailInput: {
    width: "80%",
    margin: 10,
  },

  messageInput: {
    width: "80%",
    margin: 10,
  },
}));

export const Contact = () => {
  const classes = useStyles();

  const { register, handleSubmit, reset } = useForm();
  // TODO: add error handling using medium article.
  const onSubmit = async (data) => {
    const { firstName, lastName, email, message } = data;
    try {
      const templateParams = {
        firstName,
        lastName,
        email,
        message,
      };

      await emailjs.send(
        process.env.REACT_APP_SERVICE_ID.toString(),
        process.env.REACT_APP_TEMPLATE_ID.toString(),
        templateParams,
        process.env.REACT_APP_USER_ID.toString()
      );
      reset();
      toastifySuccess();
    } catch (e) {
      console.log(e);
    }
  };

  const toastifySuccess = () => {
    toast("Your email was sent to the Swing Left NYC team!", {
      position: "bottom-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback success",
      toastId: "notifyToast",
    });
  };

  return (
    <div>
      <h1 id="contact-header">
        {`Want to get involved or have a question for us? `}
        <a
          href="mailto:events@swingleft.nyc"
          target="_blank"
          rel="noopener noreferrer"
          className="email-link"
        >
          {`Email us! `}
        </a>
        You can also
        <a
          href="https://swingleft.org/group/swing-left-nyc"
          target="_blank"
          rel="noopener noreferrer"
          className="email-link"
        >
          {` join our group.`}
        </a>
      </h1>
      <div>
        <div id="form-container">
          <hr />
          <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
            <h2 id="form-header">
              Please enter your contact information and message.
            </h2>
            <div className="break"></div>
            <TextField
              className={classes.nameInput}
              label="First Name"
              name="firstName"
              type="text"
              variant="outlined"
              {...register("firstName", {
                required: {
                  value: true,
                  message: "Please enter your first name",
                },
                maxLength: {
                  value: 30,
                  message: "Please use 30 characters or less",
                },
              })}
            />

            <TextField
              className={classes.nameInput}
              label="Last Name"
              name="lastName"
              type="text"
              variant="outlined"
              {...register("lastName", {
                required: {
                  value: true,
                  message: "Please enter your last name",
                },
                maxLength: {
                  value: 30,
                  message: "Please use 30 characters or less",
                },
              })}
            />
            <TextField
              className={classes.emailInput}
              label="Email"
              name="email"
              type="text"
              variant="outlined"
              {...register("email", {
                required: { value: true, message: "Please enter your email" },
              })}
            />
            <TextField
              className={classes.messageInput}
              label="Message"
              name="message"
              type="text"
              variant="outlined"
              size="medium"
              multiline={true}
              {...register("message", {
                required: { value: true, message: "Please enter your message" },
              })}
            />
            <div className="break"></div>
            <Button
              className={classes.button}
              variant="contained"
              type="submit"
              color="primary"
              size="large"
            >
              Send Message
            </Button>
          </form>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};
