import React from "react";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import Link from "@mui/material/Link";

import "../../index.css";

export const SocialIcons = () => {
  return (
    <div className="social-icons">
      <Link
        href="https://twitter.com/swingleftnyc"
        target="_blank"
        rel="noopener noreferrer"
      >
        <TwitterIcon fontSize="large" />
      </Link>
      <Link
        href="https://www.facebook.com/groups/1974895136078698"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FacebookIcon fontSize="large" />
      </Link>
    </div>
  );
};
