import React from "react";
import RepForm from "./RepForm";

export const Representatives = () => {
  return (
    <div>
      <RepForm />
    </div>
  );
};
