import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MenuItems } from "./MenuItems";
import { Button } from "../Button.jsx";
import { Dropdown } from "./Dropdown.jsx";
import { Modal } from "./Modal.jsx";
import { SocialIcons } from "./SocialIcons";
import logo from "./swingLeft_Logo_color 1.svg";
import "../../index.css";

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [modalOpen, setModal] = useState(false);

  const handleClick = () => {
    setClick(!click);
  };

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(!dropdown);
    } else {
      setDropdown(false);
    }
  };

  return (
    <nav className="Items">
      <Link to="/">
        <img
          className="swingleft-logo"
          src={logo}
          alt="SwingLeft NYC logo"
          title="SwingLeft NYC logo"
        />
      </Link>
      <div className="burger" onClick={handleClick}>
        <span className={click ? "x" : "open"}></span>
      </div>
      <ul className={click ? "nav-menu active" : "nav-menu"}>
        {MenuItems.map((item, index) => {
          if (item.cName === "nav-links") {
            return (
              <li key={index}>
                <Link
                  className={item.cName}
                  to={item.path}
                  onClick={handleClick}
                >
                  {item.title}
                </Link>
              </li>
            );
          } else if (item.cName === "nav-links-caret") {
            return (
              <li
                key={index}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onClick={onMouseLeave}
              >
                <Link className={item.cName} to={item.path}>
                  {item.title}
                </Link>
                {dropdown && <Dropdown handleClick={handleClick} />}
              </li>
            );
          } else {
            return null;
          }
        })}
        <Button onClick={() => setModal(true)}>Get Involved</Button>
        <li>
          <SocialIcons />
        </li>
      </ul>
      <Modal open={modalOpen} onClose={() => setModal(false)} />
    </nav>
  );
}

export default Navbar;
