import { useState, useEffect } from "react";
import axios from "axios";
import { formatNewEvents } from "../utils/eventDataHelpers";

const useFetchEvents = (zipcode, maxDist, perPage) => {
  const [events, setEvents] = useState([]);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axios.get(
          `https://api.mobilize.us/v1/organizations/159/events?timeslot_start=gte_now&zipcode=${zipcode}&max_dist=${maxDist}&per_page=${perPage}`
        );
        const formattedEvents = formatNewEvents(data.data);
        setEvents(formattedEvents);
      } catch (error) {
        setIsError(true);
      }
    }
    fetchData();
  }, [zipcode, maxDist, perPage]);

  return { events, isError };
};

export default useFetchEvents;
